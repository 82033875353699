<template>
  <main>
    <div class="container">
      <div class="container__page">
        <div class="container__content">

          <h1>&laquo;МегаФон Ритейл&raquo;&nbsp;&mdash; 12 лет!</h1>

          <hr>

          <h2>Отпразднуем день рождения все вместе!</h2>
          <p>В&nbsp;честь этого события дружная команда &laquo;МегаФон Ритейл&raquo; дарит тебе персональный подарок.<br>
          Номинал твоего подарка <b class="secondary-text">2500&nbsp;рублей</b>.</p>

          <h3>Как получить подарок?</h3>

          <p><b class="secondary-text">Шаг 1:</b> нажми на&nbsp;кнопку &laquo;Получить подарок&raquo;.</p>
          <p><b class="secondary-text">Шаг 2:</b> введи свой <nobr>e-mail</nobr>, к&nbsp;которому у&nbsp;тебя есть доступ, электронный сертификат придет на&nbsp;указанную электронную почту.</p>
          <p><b class="secondary-text">Шаг 3:</b> заходи в&nbsp;письмо, нажми на&nbsp;кнопку &laquo;Активация сертификата&raquo; и&nbsp;выбери себе один или несколько подарков на&nbsp;сумму не&nbsp;более чем 2500&nbsp;рублей.</p>
          <p><b class="secondary-text">Шаг 4:</b> выбранные сертификаты будут доставлены на&nbsp;электронную почту.</p>

          <div>
            <router-link class="button button--large button--accent button--rounded" :to="{ name: 'Catalog' }">Получить подарок!</router-link>
          </div>
          <br>

          <hr>

          <h3>Возникли вопросы?</h3>
          <p>Мы на&nbsp;связи ежедневно <b>с 08:00 до 20:00 (МСК)</b></p>
          <p>Служба заботы о клиентах: <b><a href="tel:88007009090">8&nbsp;800&nbsp;700 90 90</a></b><br>
          Email: <b><a href="mailto:support@giftery.ru">support@giftery.ru</a></b></p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 32.3%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 32.3%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }

  .text-danger {
    color: #c00;
    font-weight: bold;
  }
</style>
